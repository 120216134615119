const ar = {
  instructionDes: "تجسيد الفن والتراث والحصرية.",
  instructionDiscover: "تعرّف على المزيد",
  instructionPremier: "”الوجهة الأولى للمزادات والمبيعات",
  instructionPremier1: "الخاصة للمقتنيات الفاخرة.“",
  instructionPremier2: "اكتشفوا فالكونز",
  instructionvideo1: "ذي إس كونسيبت",
  instructionvideo2: "الساعات",
  instructionvideo3: "التراث",
  instructionvideo4: "القطع التذكارية الرياضية",
  instructionSpotlight: "تسليط الضوء على",
  instructionCartier: "كارتييه",
  instructionEpitomizing: "تجسيد الفن والتراث والحصرية.",
  instructionSpotlightDiscover: "اكتشفوا فالكونز",
  instructionHotDiscover: "اكتشفوا المزيد",
  instructionViewDetails: "عرض التفاصيل",
  instructionSortBy: "ترتيب حسب",
  instructionFilterBy: "فلترة حسب",
  instructionCategory: "الفئة",
  instructionPrice: "السعر",
  instructionBrand: "العلامات التجارية",
  instructionCondtion: "الحالة",
  instructionGender: "الجنس",
  instructionFeatured: "الخصائص",
  instructionFeaturedDetail:
    "استكشفوا قسم الأسئلة الشائعة لدينا، حيث ستجدون إجابات منسَّقة بدقة على استفساراتكم، ما يضمن تجربة سلسة وفخمة مع علامتنا التجارية المميزة.",
  instructionClearAll: "مسح الكل",
  instructionSave: "حفظ",
};
const en = {
  instructionClearAll: "RESET CHANGES",
  instructionSave: "APPLY",
  instructionFeatured: "Featured",
  instructionFeaturedDetail:
    "The first time Audemars Piguet has used this technique, making each dial a unique creation since the distribution of iridescent colours varies from one dial to the next.",
  instructionViewDetails: "View Details",
  instructionSortBy: "Sort By",
  instructionFilterBy: "Filter By",
  instructionCategory: "CATEGORY",
  instructionPrice: "PRICE",
  instructionBrand: "BRANDS",
  instructionCondtion: "CONDITION",
  instructionGender: "GENDER",
  instructionDes: "Epitomizing artistry, heritage & exclusivity.",
  instructionDiscover: "DISCOVER MORE",
  instructionPremier: "“The premier destination for auctions and",
  instructionPremier1: "private sales of luxury collectibles.”",
  instructionPremier2: "DISCOVER FALCONS",
  instructionPremierWap: "“The premier destination for",
  instructionPremierWap1: "auctions and private sale of",
  instructionPremierWap2: "luxury collectibles.”",
  instructionPremierWap3: "DISCOVER MORE",
  instructionvideo1: "TIMEPIECES",
  instructionvideo2: "HERITAGE",
  instructionvideo3: "SPORTS MEMORABILIA",
  instructionvideo4: "THE S CONCEPT",
  instructionSpotlight: "SPOTLIGHT ON",
  instructionCartier: "Cartier",
  instructionEpitomizing: "Epitomizing artistry, heritage & exclusivity.",
  instructionSpotlightDiscover: "DISCOVER FALCONS",
  instructionHotDiscover: "DISCOVER FALCONS",
};

export { ar, en };

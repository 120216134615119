export const auctionRouter = [
  {
    path: "/v2/carAuction/:productId",//已废弃
    component: () => import("@/views/carAuction/auctionIndex.vue"),
    name: "carAuction",
  },
  {
    path: '/v2/burjAlArab/:auctionId?',//已废弃
    component: () => import('@/views/burjAlArab/burjAlArabIndex.vue'),
    name: 'burjAlArab',
  },
  {
    path: '/v2/auctionInfo',
    component: () => import('@/views/burjAlArab/auctionInfo.vue'),
    name: 'auctionInfo'
 },
  {
    path: "/v2/handbagsauction/:productId?", // handbag productDetail
    component: () => import("@/views/bagsDetailPage/bagsDetail.vue"),
    name: "handbagsAuction",
  },
  {
    path: "/v2/timepiecesauction/:productId?", //手表详情页
    component: () => import("@/views/DetailPagesList/DetailPagesIndex.vue"),
    name: "timepiecesAuction",
  },
  {
    path: "/v2/carsauction/:id?",
    component: () => import("@/views/car/CarDetail.vue"),
    name: "carsAuction",
  },
  {
    path: '/v2/RegistrationFrom',
    component: () => import('@/views/RegistrationFrom/RegistrationFrom.vue'),
    name: 'RegistrationFrom'

 },

];

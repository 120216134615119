/*
 * @Author: zouzirui
 * @Date: 2024-07-25 15:48:47
 * @LastEditors: yangpeng
 * @LastEditTime: 2024-09-02 14:24:36
 * @FilePath: \falcons_ecommerce_web\falcons_ecommerce-web\src\i18n\langs\detailsAndetc.js
 * @Description:
 * ar中带英文说明没有给对应的文案
 */

const ar = {
	JeansText: "الأناقة الخالدة لحقيبة هيرميس كيلي",
	JeansDate: "15 مايو 2024",
	shoesText: " كيف تجد الأزياءُ الفاخرة منازل جديدة في المزادات",
	REFERENCE:'الرقم المرجعي',
	shoesDate:'1 أكتوبر، 2024',
	Trompefeathers: 'أزرق دخاني فائق الأناقة',
	BRAND:'العلامة التجارية',
	Receivepayment:'ستستلمون دفعتكم خلال 14 يوم عمل بعد بيع قِطعتكم.',
	GENDER:'الجنس',
	ATHLETE:"",
	DIAL:'المينا',
	PRODUCT:'المنتَج',
	BRACELETMATERIAL:'خامة السوار',
	PRODUCTTYPE:'تفاصيل المنتج',
	EXTERIALMATERIAL:'الخامة الخارجية',
	HARDWARE:'الإكسسوارات المعدنية',
	SizeText:'المقاس (الطول*العرض*الارتفاع)',
	COUNTRYOFORIGIN:'بلد المنشأ',
	YEAROFPRODUCTION:'سنة الإنتاج',
	COLOUR:"اللون",
	OTHERINCLUSIONS:'مرفقات أخرى',
	MECHANISM:'الآلية',
	CASEMATERIAL:'خامة العلبة',
	BRACELETCOLOUR:"لون السوار",
	SPECIALFEATURES:"الخصائص المميزة",
	SPORT:'الرياضة',
	SIGNED:'موقَّعة',
	TEAMText:'فريق',
	SIZE:'نوع المنتَج',
	MATERIALS:'الخامات',
	MODELYEAR:'سنة الموديل',
	GEMSTONE:'الأحجار الكريمة',
	MATERIALText:'الخامات',
	CARATWEIGHT:'الوزن بالقيراط',
	YEARText:'السنة',
	GRADE:"التقييم",
	SCORE:"الدرجة",
	YEARSET:'العام والمجموعة',
	MAKE:'العلامة التجارية',
	CYLINDER:"عدد الأسطوانات",
	MILEAGE:'المسافة المقطوعة',
	ROOKIECARDS:"بطاقات المبتدئين",
	PRISTINE:'بحالتها الإصلية',
	CONDITIONPARAMETERS:'معايير تحديد الحالة',
	TYPEText:'الخامة',
	EXTERIORCOLOUR:'اللون الخارجي',
	TaxesIncluded:'الضرائب',
	GEMSTONEText:'قطع الأحجار الكريمة وشكلها',
	InternationalShippingAvailable:'خدمات الشحن الدولي متاحة',
	MODEL:"الموديل",
	ITEMText:"القطعة",
	ProductDetails:"تفاصيل المنتج",
	DISCOVERMORE:'تسوَّقوا المجموعة',
	AuthenticityGuaranteed:'الأصالة مضمونة',
	Collectibles:"المقتنيات",
	FineJewelry:"المجوهرات",
	Handbags:"حقائب اليد",
	ShoesText:'الأحذية',
	accessoriestext:"شرحُ ظاهرة رولكس",
	SportsMerorabilia:'ميزوري سبورت',
	SportsCards:"بطاقات التداول الرياضية",
	ReadytoWear:'الملابس الجاهزة',
	AutomobilesText:'السيارات',
	accessoriesDate:"20 يوليو 2024",
	CASEDIAMETER:'مقاس العلبة (مم)',
	TRANSMISSION:'ناقل الحركة',
	Pristine:'بحالتها الإصلية',
	Excellent:'بحالة ممتازة ',
	GoodText:" بحالة جيدة ",
	FairText:"بحالة مقبولة",
	LeatherGoods:'الجلديات',
	leatherBagDate:'31 يوليو 2024',
	bagsDate:'اكتشفوا المزيد',
	CATEGORY:'الفئة',
	STARTSELLING:'ابدأوا البيع',
	InauguralAuction:'المزاد الافتتاحي',
	SeptemberDate:'28 سبتمبر',
	BurjAlArabDubai:'برج العرب',
	AremarkableText:'رحلةٌ مذهلة نحو جمع الكنوز النادرة والفريدة.',
	BiddingOptions:"خيارات المشاركة في المزاد",
	InPersonBidding:'المزايدة عبر الهاتف',
	TelephoneBidding:'المزايدة بشكل شخصي',
	Thepremier:'الوجهة المثلى للمزادات والبيع الخاص',
	privatesales:'للمقتنيات الراقية',
	DISCOVERFALCONS:'اكتشفوا فالكونز',
	REGISTERHERE:'التسجيل هنا',
	VERIFICATIONCOMPLETED:'اكتملت عملية التحقُّق.',
	CONTINUETOVERIFICATION:'CONTINUE TO VERIFICATION',
	PAYMENTPENDING:'في انتظار الدفع',
	SECUREDEPOSIT:'قم بتأمين مكانك من خلال تسديد مبلغ إيداع قابل للاسترداد.',
	VERIFICATIONPENDING:'في انتظار التحقُّق',
	CLICKTOVIEWLOTS:"عرض نتائج المزاد",
	ParticipateText:"اختبروا قمَّة الحصرية في مزادنا المباشر. سجِّلوا مسبقاً لحجز مقاعدكم.",
	ExperienceText:'شاركوا عن بُعدٍ بالمزايدة عبر الهاتف، حيث يمكنكم الوصول إلى مجموعة مُختارة من المقتنيات التي اجتازت أدقَّ اختبارات الأصالة.',
	AtFalcons:'في فالكونز، كنوزٌ خالدةٌ تتجاوز حدودَ الزمن.',
	Luxurywithinreach:'الرفاهيةُ بين يديكم.',
	Haveityourway:'افعلها بطريقتك.',
	SELLText:'البيع',
	STEPTwo:'لخطوة 2',
	Ifyouresatisfied:'إذا كنتم راضين عن السعر المقدَّم، فيمكنكم ترتيب توصيلمجاني لنتحقَّق من أصالة القطعة ونقدم عرض السعر النهائي.',
	AUCTIONText:'البيع بالمزاد',
	STARTSELLINGWITHFALCONS:'ابدأ البيع مع فالكونز',
	HOWITWORKS:'كيف يتم الأمر',
	Submityouritem:'قدِّموا القِطعة الخاصة بكم عبر الإنترنت ليقدِّم لكم فريقنا 	عرضَ السعر الأوّلي.',
	STEPOne:'الخطوة 1',
	ArrangeCollection:'ترتيب التوصيل',
	PreliminaryQuotations:'عروض الأسعار الأولية',
	STEPThree:'الخطوة 3',
	yesText:'نعم',
	Ourteam:"عند وصول قطعتكم إلى فالكونز، سيقوم فريق الخبراء لدينا بإجراء تقييم شامل لها ليقدم لكم عرض السعر النهائي.",
	Onceapproved:"بعد قبول القطعة، سنقوم بتحسينها والتقاط صور عالية الدقة لها، ثم سندرجها على موقعنا. بمجرَّد بيعها، سيُضاف المبلغ المستحَق إلى محفظة فالكونز الخاصة بكم.",
	Afterapproval:"بعد الموافقة على القطعة، سنقوم بتجهيزها والتقاط صور عاليةالجودة لها، ثم سندرجها على موقعنا. بمجرَّد بيع القطعة، ستتمضافة المبلغ المستحَق إلى محفظة فالكونز الخاصة بك.",
	ListingEarn:'إدراج القطعة والربح',
	CancelText:'إلغاء',
	ExperiencetheFalconsdifference:'اختبروا تميُّزَ فالكونز',
	Howdoes:'كيف يتم البيع على موقع فالكونز؟',
	SellingwithFalcons:'شرح عملية البيع مع',
	explainedseconds:'فالكونز في ٣٠ ثانية',
	noText:"لا",
	STEPFour:'لخطوة 4',
	usdText:' دولار أمريكي',
	FinalQuotation:'عرض السعر النهائي',
	Thesoldprice:'سيتم تحديد سعرِ البيع من خلال المزايدة بين المشاركين، حيث سيتمكَّن المُزايدُ الذي يقدِّم أعلى سعرٍ خلالَ الإطار الزَّمني المحدَّد من شراء القطعة.',
	Itemsaredisplayed:'يتم عرضُ القِطَع بأسعارٍ ثابتة في سوقنا للشراء الفوري. بمجرَّد التحقُّق من أصالتها والموافقة عليها، ستنضمُّ القطعةُ الخاصة بك إلى قوائم القِطَع لدينا.',
	Earnthehighest:"حقِّقوا أعلى قيمة لمقتنياتكم مع فالكونز برسوم عمولة غير مسبوقة بنسبة 15٪.",
	PristineDicDesc:'القِطَع بحالتها الأصلية تكون جديدة وغير مستخدَمة وخالية من العيوب، وتظهر الأناقة الخالدة وحِرفية التصنيع.',
	readytoWearContent: 'تتميز هذه الساعة الفاخرة بمينا باللون الأزرق الدخاني الساحر الذي يبرز نمطَ "غراند تابيسيري" الأيقوني المعقَّد. المينا مزيَّنٌ بعلامات ساعات من الذهب الأبيض وعقارب رويال أوك، جميعُها تتألَّق بطبقة مضيئة لتعزيز الرؤية.',
	Used:'مستعمل'
};
const en = {
	STARTSELLINGWITHFALCONS:'START SELLING WITH FALCONS',
	HOWITWORKS:'HOW IT WORKS',
	ExperiencetheFalconsdifference:'Experience the Falcons’ difference',
	CancelText:'Cancel',
	SellingwithFalcons:'Selling with Falcons',
	explainedseconds:'explained in 30 seconds',
	STEPThree:'STEP 3',
	STEPFour:'STEP 4',
	Ourteam:'Our team of experts will thoroughly evaluate your item upon its arrival at Falcons and provide a final quotation.',
	FinalQuotation:'Final Quotation',
	Onceapproved:"Once approved, we will refine the item, take high-quality photos, and list it. When it sells, the owed amount will be credited to your Falcons Wallet.",
	STARTSELLING:'START SELLING',
	usdText:"USD",
	Howdoes:'How does selling at falcons work?',
	ListingEarn:'Listing & Earn',
	Submityouritem:'Submit your item online and our team will give you a preliminary quotation.',
	STEPOne:'STEP 1',
	Afterapproval:"After approval, we'll refine the piece, capture high-quality photos, and list it. Once the piece sells, the owed amount will be credited to your Falcons Wallet.",
	PreliminaryQuotations:'Preliminary Quotations',
	Collectibles:"Collectibles",
	Earnthehighest:' Earn the highest value for your collectibles with Falcons’ unmatched 15% commission fee.',
	Receivepayment:'Receive payment within 14 business days after your item is sold.',
	Luxurywithinreach:"Luxury within reach.",
	PAYMENTPENDING:'PAYMENT PENDING',
	VERIFICATIONCOMPLETED:'VERIFICATION COMPLETED',
	SECUREDEPOSIT:'SECURE YOUR SPOT WITH A REFUNDABLE DEPOSIT',
	CLICKTOVIEWLOTS:"VIEW RESULTS",
	VERIFICATIONPENDING:'VERIFICATION PENDING',
	CONTINUETOVERIFICATION:'CONTINUE TO VERIFICATION',
	privatesales:'private sales of luxury collectibles.”',
	DISCOVERFALCONS:'DISCOVER FALCONS',
	Thepremier:'“The premier destination for auctions and',
	TelephoneBidding:'Telephone Bidding',
	ParticipateText:'Participate remotely via telephone bidding, gaining access to a curated selection of authenticated collectibles.',
	InauguralAuction:'Inaugural Auction',
	ExperienceText:'Experience the pinnacle of exclusivity at our live auction. Register in advance to secure your spot.',
	BiddingOptions:'BIDDING OPTIONS',
	InPersonBidding:'In Person Bidding',
	SeptemberDate:'September 28th Burj Al Arab, Dubai',
	AremarkableText:"A remarkable journey towards acquiring rare and coveted treasures.",
	BurjAlArabDubai:'Burj Al Arab, Dubai',
	CATEGORY:'CATEGORY',
	ArrangeCollection:'Arrange Collection',
	Ifyouresatisfied:"If you're satisfied, you may arrange a free collection for authentication and final quotation.",
	Thesoldprice:'The sold price will be determined through bidding among participants. The bidder offering the highest price within the effective timeframe will secure the purchase of the item.',
	AUCTIONText:'AUCTION',
	Itemsaredisplayed:'Items are displayed at fixed prices on our marketplace for immediate purchase. Once authenticated and approved, your item will join our listings.',
	Haveityourway:'Have it your way.',
	AtFalcons:'At Falcons, timeless treasures transcend time.',
	FairText:"Fair",
	STEPTwo:'STEP 2',
	GoodText:"Good",
	SELLText:'SELL',
	leatherBagDate:"July 31st 2024",
	Pristine:'Pristine',
	Excellent:"Excellent",
	TRANSMISSION:"TRANSMISSION",
	AutomobilesText:'Automobiles',
	accessoriesDate:"July 20th 2024",
	bagsDate:'April 25th, 2024',
	SportsMerorabilia:'Sports Merorabilia',
	JeansText: 'The Timeless Elegance of the Hermès Kelly',
	ReadytoWear:"Ready to Wear",
	SportsCards:"Sports Cards",
	accessoriestext:"The Rolex Phenomenon Explained",
	BRAND:'BRAND',
	ShoesText:"Shoes",
	ITEMText:'ITEM',
	DIAL:'DIAL',
	FineJewelry:'Jewellery',
	LeatherGoods:'Leather Goods',
	Handbags:"Hand bags",
	GENDER:'GENDER',
	TaxesIncluded:'Taxes',
	PRISTINE:'PRISTINE',
	InternationalShippingAvailable:'International Shipping Available',
	CYLINDER:"CYLINDER",
	AuthenticityGuaranteed:'Authenticity Guaranteed',
	CONDITIONPARAMETERS:'CONDITION PARAMETERS',
	EXTERIORCOLOUR:'EXTERIOR COLOUR',
	SIGNED:'SIGNED',
	noText:"No",
	MECHANISM:'MECHANISM',
	GRADE:"GRADE",
	ProductDetails:'Product Details',
	YEARSET:'YEAR & SET',
	SCORE:'SCORE',
	SPECIALFEATURES:"SPECIAL FEATURES",
	YEARText:'YEAR',
	GEMSTONE:'GEMSTONE',
	ROOKIECARDS:"ROOKIE CARDS",
	PRODUCT:'PRODUCT',
	OTHERINCLUSIONS:"OTHER INCLUSIONS",
	PRODUCTTYPE:'PRODUCT TYPE',
	TYPEText:'TYPE',
	SizeText:'Size (L*W*H)',
	REFERENCE: 'REFERENCE',
	REGISTERHERE:'REGISTER HERE',
	JeansDate: "May 15th, 2024",
	COLOUR:'COLOUR',
	MILEAGE:'MILEAGE',
	SIZES:'SIZES',
	SPORT:'SPORT',
	MATERIALS:'MATERIALS',
	CARATWEIGHT:'CARAT WEIGHT',
	shoesText: 'How Luxury Fashion Finds New Homes in the Bid of a Gavel',
	shoesDate:'October 1st, 2024',
	EXTERIALMATERIAL:'EXTERIAL MATERIAL',
	COUNTRYOFORIGIN: 'COUNTRY OF ORIGIN',
	HARDWARE:'HARDWARE',
	TEAM:"TEAM",
	ATHLETE:'ATHLETE',
	MATERIALText:'MATERIAL',
	PristineDicDesc:'Items in pristine condition are brand new, unused, and free from any defects.They exhibit flawless quality and are in perfect, mint condition.',
	MAKE:"MAKE",
	Trompefeathers: 'Trompe l’oeil feathers',
	CASEMATERIAL:'CASE MATERIAL',
	CASEDIAMETER:'CASE DIAMETER (MM)',
	BRACELETCOLOUR:'BRACELET COLOUR',
	BRACELETMATERIAL:'BRACELET MATERIAL',
	YEAROFPRODUCTION:"YEAR OF PRODUCTION",
	MODELYEAR:'MODEL YEAR',
	MODEL:'MODEL',
	yesText:'Yes',
	DISCOVERMORE:'DISCOVER MORE',
	GEMSTONEText:"GEMSTONE CUT AND SHAPE",
	readytoWearContent: 'Black velvet cape with Phoenix-wing shaped shoulders adorned with a 3D chrome trompe l’oeil feathers embroidery.',
	Used:'USED'
};

export { ar, en };

const birdEn = {
  Buyer: 'Buyer',
  Seller: 'Seller',
  Referal: 'Invites',
  General: 'General',
  Point: 'Points',
  OneTime: 'One-time',
  Repeatable: 'Repeatable',
  ReferalNumber: 'Your invitation code',
  Balance: 'Balance',
  LoyaltyPoints: 'EFAH Points',
  DigitalAssets: 'Digital assets',
  Mission: 'Mission',
  PointEarned: 'EFAH Points earned',
  LoyaltyPoint: 'EFAH Points balance',
  Sign: 'DAILY CHECK-IN',
  InvitationCode: 'Submit invitation code here',
  Blockchain: 'Blockchain',
  Account_balance: 'Amount',
  Google: 'Google',
  Facebook: 'Facebook',
  continueWithEmail: 'CONTINUE WITH EMAIL',
  AuthorizeWeb3Auth: 'Authorize your account with Web3Auth',
  No_data: 'No data',
  CheckIn: 'Check in successfully',
  CopySuccess: 'Copied',
  Token_name: 'Token name',
  Holder_adress: 'Holder address',
  Available:'AVAILABLE',
  Not_available: 'Not available',
  AddChanges: 'SAVE CHANGES',
  ChangeAvatar: 'Change Avatar',
  Selectavatar: 'SELECT YOUR AVATAR',
  Uploadphoto: 'UPLOAD PHOTO',
  continueWithMetaMask: 'Connect with MetaMask',
  continueWithWeb3Auth: 'Connect with Web3Auth Wallet',
  walletConnecting: 'Wallet Connecting...',
  openYourWallet: 'Open Your Wallet',
  InstalledMetaMask: 'Haven’t installed MetaMask?',
  ToInstalledMetaMask: 'Go to web and Install MetaMask for browser',
  Okay: 'OKAY',
  DisconnectMetaMask: 'Disconnect MetaMask',
  LogoutWeb3Auth: 'Logout Web3Auth',
  Cut: 'Confirm this cut',
  Exceed: 'Avatar size cannot exceed 5M',
  Buy: 'Buy',
  ConfirmDeletePurchaseItem: 'Confirm to delete this item from purchases?',
  Clear: 'Clear',
  Help: 'Need some help? You might be able to find an answer to your question on our',
  Page: 'page',
  PhoneNumber: 'Phone number',
  OurCustomer: 'Our customer service advisors are available Monday to Sunday (10am – 5pm).',
  WeWill: 'We will aim to respond to you as promptly as possible. If your query relates to a specific order, please include your order number.',
  ItemSold: 'ITEM SOLD',
  Verify: 'VERIFY',
  ProductType: 'Product Type',
  Pleaseagree: 'By submitting your item, you agree to the Terms & Conditions of Falcons.',
  BySubmitting: 'By submitting your item, you agree to the',
  OtherInclusions: 'Other Inclusions',
  Color: 'Color',
  NewFWAddress: 'Feeder Commercial Center, Me’Aisem First, Dubai Production City',
  LocationOnGoogleMap: 'LOCATION ON GOOGLE MAPS',
  EstimatedPayout: 'Estimated Payout',
  TotalPayable: 'Total Payable',
  detailTitle04: 'Taxes',
  detailText04: 'Listed prices may be subject to an additional 5% VAT and any other applicable taxes.',
  ReadMore: 'Read more',
  BreakLive: 'Watch It Break Live',
  BreakLive_desc1: 'Immerse yourself in the world of high-end sports cards as we unveil treasures from F1, basketball, football, baseball, hockey, and beyond.',
  BreakLive_desc2: 'Our live broadcasts elevate the art of card collecting to new heights, offering aficionados a front-row seat to unveil rare gems, coveted autographs, and iconic memorabilia.',
  OnlineLive: 'Online Boutique',
  OnlineLive_desc: 'Journey through the illustrious heritage of sports card collecting. From the gilded age of vintage classics to the modern rare collections, Falcons  celebrates the enduring legacy of the hobby with reverence and sophistication.',
  SignUpAgreeEmail: 'Receive exclusive updates from Falcons.',
  dialColor: 'Dial Color',
  firstName: 'First Name',
  lastName: 'Last Name',
  ValidateFirstName: 'Minimum of 8 characters, combining letters and numbers.',
  ValidateLastName: 'Minimum of 8 characters, combining letters and numbers.',
  firstNameRule: 'First name should be between 2 and 50 characters',
  lastNameRule: 'Last name should be between 2 and 50 characters',
  exploreMore: 'Explore More',
  wordOfFalcons: 'World of Falcons',
  blogDec01: 'Where exquisite stories unfold.',
  blogDec02: 'The Birkin : A  Timeless Investment',
  blogDec03: 'The Rolex Phenomenon Explained',
  blogDec04: 'The Rise of Pre-owned Luxury',
  blogBir01: 'May 14th, 2024',
  blogBir02: 'May 5th, 2024',
  blogBir03: 'April 25th, 2024',
  investment: 'A Timeless Investment',
  birkin: 'The Birkin',
  blogBannerDesc: `A symbol of luxury and sophistication, owning a Birkin isn't just about having a beautiful accessory; it's about making a lasting investment in both your style and financial portfolio.`,
  Accessories: 'Accessories(such as straps,manuals eg.)',
  AccessoriesTxt: 'Accessories',
  OriiginalPackaging: 'Original Packaging',
  chooseAccessories: 'Please choose Accessories',
  chooseOriginal: 'Please choose Original Packaging',
  proof: 'Proof of Authenticity',
  ImageType: 'Image formats',
  documentsType: 'Document formats',
  Receipts: 'Receipts',
  ReceiptsDesc: 'Provide original purchhase receipts or proof of purchase.',
  Certificates: 'Certificates',
  CertificatesDesc: 'Include certificates of authenticity if available.',
  Serial: 'Serial Numbers',
  SerialDesc: 'Ensure that any serial numbers or Identifying marks are visible in the photos.',
  KeepInMind: 'To Keep in mind',
  CloseUp: 'Close-Up Shots:',
  CloseUpDesc: 'Include close-up shots of brand tags, serial numbers, and any imperfections.',
  HighQuality: 'High-Quality Photos:',
  HighQualityDesc: 'Upload high-resolution images of the item from multiple angles (front, back, sides, and interior).',
  WhiteBackground: 'White Background:',
  WhiteBackgroundDesc: 'All pictures must be taken on a white background with no visible images or objects other than the item in the picture.',
  typeHere: 'If Other, Please type here',
  cartEmpty: 'Oh no your cart is empty'
}

export default birdEn
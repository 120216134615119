const ar = {
  auctionLots: "جميع القِطع",
  auctionTimepieces: "الساعات",
  auctionHandbags: "حقائب اليد",
  auctionCars: "السيارات",
  auctionLotlh: "رقم القِطعة (من الأدنى إلى الأعلى)",
  auctionLothl: "رقم القِطعة (من الأعلى إلى الأدنى)",
  auctionReslh: "الحد الأدنى للسعر (من الأدنى إلى الأعلى)",
  auctionReshl: "الحد الأدنى للسعر (من الأعلى إلى الأدنى)",
  auctionEstimate: "السعر التقديري",
  auctionReserve: "الحد الأدنى للسعر",
  auctionLot: "القطعة",
  auctionTitle: "اكتشفوا المزيد",
  carTips: "عرض المنتج غير مسموح بالمزاد",
  replayButton:'شاهد البث الكامل',
  liveReplay: "استمتعوا بإثارة البحث عن الكنوز المخفية مع فعالية فالكونز x شاينز الحصرية لفتح بطاقات التداول الرياضية في برج العرب المرموق في 28 سبتمبر 2024.",
  shyneInfo:
    "فالكونز تتعاون مع شاين في جلسة مباشرة تُبث حول العالم لفتح علب بطاقات التداول الرياضية في برج العرب المرموق. انضموا إلينا في جيلت بار لهذه الجلسة الخاصة، بعد مزادنا الحصري في 28 سبتمبر.",
  shyneLiveInfoOne: "هنا تتجلَّى أساطير فتح علب بطاقات التداول الرياضية.",
  shyneLiveInfoTwo: "لا تفوتوا لحظات السحب التي ستغير اللعبة.",
  liveOne: "العُلب تُفتح",
  liveTwo: "مباشرة",
  liveThree: "الآن",
  auctionTest:'المزادت',
  InauguralAuction:'المزاد الافتتاحي',
  SeptemberDate:'20 سبتمبر 2024',
  BurjAlArab:'برج العرب',
  ViewResults:'عرض نتائج المزاد',
  Staytuned:'ترقبوا مزاداتنا القادمة.',
  AllAuction:'جميع المزادات',
  PastTest:"السابقة",
  UpcomingTest:'القادمة',
};
const en = {
  auctionLots: "All Lots",
  auctionTest:'Auctions',
  auctionTimepieces: "Timepieces",
  auctionHandbags: "Handbags",
  auctionCars: "Automobiles",
  auctionLotlh: "Lot Number (low to high)",
  auctionLothl: "Lot Number (high to low)",
  auctionReslh: "Reserve (low to high)",
  auctionReshl: "Reserve (high to low)",
  auctionEstimate: "ESTIMATE",
  auctionReserve: "RESERVE",
  auctionLot: "LOT",
  auctionTitle: "Discover more lots",
  carTips: "Product make an offer not allowed for auction",
  replayButton:'WATCH THE FULL STREAM',
  liveReplay: "Experience the thrill of uncovering hidden treasures with the exclusive Falcons x Shyne sports live breaking event, held at the prestigious Burj Al Arab on September 28, 2024.",
  shyneInfo:
    "Falcons and Shyne are partnering for a globally streamed live sports card break at the prestigious Burj Al Arab. Join us at the Gilt Bar for this special breaking session, following our exclusive auction on September 28.",
  shyneLiveInfoOne: "This is where the next breaking legends are made.",
  shyneLiveInfoTwo: "Don’t miss the pulls that will change the game.",
  liveOne: "UNBOXING",
  liveTwo: "LIVE",
  liveThree: "NOW",
  InauguralAuction:'Inaugural Auction',
  SeptemberDate:'September 28th 2024',
  BurjAlArab:'Burj Al Arab',
  ViewResults:'View Results',
  Staytuned:'Stay tuned for our upcoming auctions.',
  AllAuction:'All Auction',
  PastTest:'Past',
  UpcomingTest:'Upcoming'
};

export { ar, en };

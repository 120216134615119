import { Web3Auth } from "@web3auth/modal";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { WalletServicesPlugin } from "@web3auth/wallet-services-plugin";
import throttle from 'lodash/throttle';
const clientId = 'BP9PTZ0NFUBvQ4wGZlJDWcZxCys7-gtasnIRMgvG82bAJbFfok12eHtO_yjYDWIeORsUy7j8rqEMPv96Kh2INLQ'
let web3auth;
let walletServicesPlugin;

const chainConfig = {
    chainNamespace: "eip155",
    chainId: "0xaa36a7",
    rpcTarget: "https://rpc.ankr.com/eth_sepolia",
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: "Ethereum Sepolia Testnet",
    blockExplorerUrl: "https://sepolia.etherscan.io",
    ticker: "ETH",
    tickerName: "Ethereum",
    logo: "https://cryptologos.cc/logos/ethereum-eth-logo.png",
};

const initAuth = async() => {
    if(web3auth) {
        return;
    }

    const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });
    web3auth = new Web3Auth({
        clientId,
        privateKeyProvider,
        web3AuthNetwork: "sapphire_mainnet",
    });

    console.log('web3auth init');
    // Add wallet service plugin
    if(!walletServicesPlugin) {
        walletServicesPlugin = new WalletServicesPlugin();
        web3auth.addPlugin(walletServicesPlugin); // Add the plugin to web3auth
    }

    console.log('web3auth initModal')
    await web3auth.initModal();
}

const showWalletLoading = () =>{
}

const throttledShowWalletUi = throttle(async () => {
    await walletServicesPlugin.showWalletUi();
}, 300);

const openWallet = async () =>{
    console.log(walletServicesPlugin.status);

    if (walletServicesPlugin.status == "connected") {
        await throttledShowWalletUi();
    }// Opens the TopUp modal
    else{
        showWalletLoading();
    }
}

 export {
     web3auth,
     walletServicesPlugin,
     initAuth,
     openWallet,
     showWalletLoading
 }